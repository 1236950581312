<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import StarRating from "vue-star-rating";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
//import {reactive} from "vue";
import { format } from "date-fns";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Information utilisateur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, StarRating, VueEasyLightbox },
  data() {
    return {
      title: "Ajouter utilisateur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      tokenUser: JSON.parse(sessionStorage.getItem("user")).tokenSalt,
      idUser: 0,
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      AutoMoto: {
        climatisation: "",
        anneeCirculation: "",
        categorie: "",
        couleur: "",
        immatriculation: "",
        model: "",
        motorisation: "",
        nomAuto: "",
        cartegrise: "",
        nombresiege: "",
      },
      fileProfil: null,
      filePermis: null,
      fileMoto: null,
      fileCni: null,
      user: {
        username: "",
        usertype: "",
        tel: "",
        email: "",
        levelRate: "",
        level: "",
        rating: "",
        active: "",
        badge: "",
      },
      preference: {
        acceptHandicape: "",
        discutIncar: "",
        eatIncar: "",
        musicIncar: "",
        smoke: "",
        stop: "",
      },
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      activity: {
        publies: 0,
        annulees: 0,
        a_venir: 0,
        effectues: 0,
        gains: 0,
        reservations_annulees: 0,
        voyages_effectues: 0,
        reservations_effectuees: 0,
      },
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      showretire: false,
      showSucess: false,
      showEchec: false,
      userDesac: null,
      avislaisses: [],
      avisrecus: [],
      conducteurData: [],
      passagerData: [],
      img: "",
      vue: false,
      visible: false,
      showModalprofil: false,
      showModalcni: false,
      showModalpermis: false,
      showModalautoMoto: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
    };
  },
  validations: {
    firstname: { required },
    lastname: { required },
    genre: { required },
    number_phone: { required, numeric },
    email: { required, email },
    password: { required },

    tooltipform: {
      fname: { required },
      lname: { required },
      username: { required },
      city: { required },
      state: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },

  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //console.log("tokenUser:", this.tokenUser);
      this.idUser = this.$route.params.idUser;
      //console.log("id:",this.idUser)
      //Chargement des données
      const data = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: this.idUser },
        false
      );

      console.log("data.data:", data.data);
      if (data && data.data) {
        //console.log("data.data:", data.data);
        this.user.username =
          data.data.personne.lastname + " " + data.data.personne.firstname;
        this.user.usertype = data.data.userType;
        this.user.tel = data.data.personne.tel;
        this.user.email = data.data.personne.email;
        this.user.levelRate = data.data.levelRate;
        this.user.level = data.data.level;
        this.user.rating = data.data.rating ? data.data.rating : "0";
        (this.user.active = data.data.active),
          (this.user.badge = data.data.userValidated),
          (this.verify.email = data.data.emailVerified),
          (this.verify.permis = data.data.permisVerified),
          (this.verify.autoMoto = data.data.autoMotoVerified),
          (this.verify.image = data.data.pictureVerified),
          (this.verify.tel = data.data.telephoneVerified),
          (this.verify.cni = data.data.cniVerified);

        if (data.data.preferences) {
          (this.preference.acceptHandicape =
            data.data.preferences.acceptPeopleDisabilities),
            (this.preference.discutIncar = data.data.preferences.discussInCar),
            (this.preference.eatIncar = data.data.preferences.eatInCar),
            (this.preference.musicIncar =
              data.data.preferences.enjoyMusicInCar),
            (this.preference.smoke = data.data.preferences.smokeInCar),
            (this.preference.stop = data.data.preferences.tolerateStops);
        }

        if (data.data.vehicule) {
          (this.AutoMoto.climatisation =
            data.data.vehicule.aClimatisation == true ? "Oui" : "Non"),
            (this.AutoMoto.anneeCirculation =
              data.data.vehicule.autoMotoAnneeMiseEnCirculation),
            (this.AutoMoto.categorie = data.data.vehicule.autoMotoCategorie),
            (this.AutoMoto.couleur = data.data.vehicule.autoMotoCouleur),
            (this.AutoMoto.immatriculation =
              data.data.vehicule.autoMotoImmatriculation),
            (this.AutoMoto.model = data.data.vehicule.autoMotoModel),
            (this.AutoMoto.motorisation =
              data.data.vehicule.autoMotoMotorisation),
            (this.AutoMoto.nomAuto = data.data.vehicule.autoMotoName),
            (this.AutoMoto.cartegrise =
              data.data.vehicule.autoMotoNumeroCarteGrise);
        }

        if (data.data.userAvatarCode !== null) {
          this.allImage.avatar =
            `http://216.158.239.208:1337/admin/image/?codeImage=` +
            data.data.userAvatarCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.avatar = require("@/assets/images/users/attente.png");
        }

        if (data.data.userCniCode) {
          this.allImage.cni =
            `http://216.158.239.208:1337/admin/image/?codeImage=` +
            data.data.userCniCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.cni = require("@/assets/images/users/attente.png");
        }

        if (data.data.vehicule) {
          if (
            data.data.vehicule.autoMotoPermisCode !== null &&
            this.tokenUser !== null
          ) {
            this.allImage.permis =
              `http://216.158.239.208:1337/admin/image/?codeImage=` +
              data.data.vehicule.autoMotoPermisCode +
              `&token=` +
              this.tokenUser;
          } else {
            this.allImage.permis = require("@/assets/images/users/attente.png");
          }
        } else {
          this.allImage.permis = require("@/assets/images/users/attente.png");
        }

        if (data.data.vehicule) {
          if (data.data.vehicule.autoMotoPictureCode !== null) {
            this.allImage.vehicule =
              `http://216.158.239.208:1337/admin/image/?codeImage=` +
              data.data.vehicule.autoMotoPictureCode +
              `&token=` +
              this.tokenUser;
          } else {
            this.allImage.vehicule = require("@/assets/images/users/attente.png");
          }
        } else {
          this.allImage.vehicule = require("@/assets/images/users/attente.png");
        }
      }

      const dataActivity = await apiRequest(
        "GET",
        "admin/trajets/activites/?id=" + this.idUser,
        undefined,
        false
      );
      //console.log("data.activity:", dataActivity.data);

      if (dataActivity && dataActivity.data) {
        console.log(dataActivity.data);
        this.activity.publies = dataActivity.data.conducteur.publie
          ? dataActivity.data.conducteur.publie
          : "0";
        this.activity.annulees = dataActivity.data.conducteur.annules
          ? dataActivity.data.conducteur.annules
          : "0";
        this.activity.a_venir = dataActivity.data.conducteur.avenir
          ? dataActivity.data.conducteur.avenir
          : "0";
        this.activity.effectues = dataActivity.data.conducteur.effectues
          ? dataActivity.data.conducteur.effectues
          : "0";
        this.activity.gains = dataActivity.data.conducteur.gains
          ? dataActivity.data.conducteur.gains
          : "0";
        this.activity.reservations_effectuees = dataActivity.data.passager
          .reserEffectues
          ? dataActivity.data.passager.reserEffectues
          : "0";
        this.activity.reservations_annulees = dataActivity.data.passager
          .reserAnnules
          ? dataActivity.data.passager.reserAnnules
          : "0";
        this.activity.voyages_effectues = dataActivity.data.passager
          .voyagesEffectues
          ? dataActivity.data.passager.voyagesEffectues
          : "0";
      }

      const avisData = await apiRequest(
        "GET",
        "admin/avis/?id=" + this.idUser,
        undefined,
        false
      );

      //console.log("avis:",avisData.data);
      if (avisData && avisData.data) {
        this.avislaisses = avisData.data.avisEmis;
        this.avisrecus = avisData.data.avisRecu;
      }

      const historiqueAdmin = await apiRequest(
        "GET",
        "admin/trajets/historique/?id=" + this.idUser,
        undefined,
        false
      );

      //console.log("historique admin:", historiqueAdmin.data)
      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.conducteur
      ) {
        const historiqueConducteurTable = historiqueAdmin.data.conducteur.map(
          (historique) => {
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
            };
          }
        );
        this.conducteurData = historiqueConducteurTable;
        //console.log('this.conducteurData:', this.conducteurData)
      }

      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.passager
      ) {
        const historiquePassagerTable = historiqueAdmin.data.passager.map(
          (historique) => {
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
            };
          }
        );
        this.passagerData = historiquePassagerTable;
        //console.log('this.conducteurData:', this.conducteurData)
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    verification: function() {
      console.log("ok");
    },

    ValidationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/small/img-2.jpg");
      this.show();
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },
    show() {
      this.visible = true;
    },

    handleFileProfil() {
      this.fileProfil = this.$refs.fileProfil.files[0];
    },
    handleFilePermis() {
      this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileMoto() {
      this.fileMoto = this.$refs.fileMoto.files[0];
    },
    handleFileCni() {
      this.fileCni = this.$refs.fileCni.files[0];
    },

    async verifyPictureRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPictureAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyAvisRefuse(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async verifyAvisAccepte(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async desactiverUser() {
      const desacUser = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.idUser },
        false
      );

      if (desacUser && desacUser.data) {
        this.init();
        //console.log("Test:",this.userDesac)
      }
    },
    async ActiverUser() {
      const activUser = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.idUser },
        false
      );

      if (activUser && activUser.data) {
        this.init();
      }
    },
    async attribuerBadge() {
      const badge = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: true,
        },
        false
      );
      if (badge && badge.data) {
        this.init();
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    },
    async retirerBadge() {
      const retirer = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: false,
        },
        false
      );
      if (retirer && retirer.data) {
        this.init();
        this.showretire = true;
      } else {
        this.showEchec = true;
      }
    },
    async addCNI() {
      this.showModalcni = false;
      if (this.fileCni) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("cni", this.fileCni);

        const returnFilecni = await apiRequest(
          "POST",
          "admin/user-cni-pic",
          formData,
          true
        );

        if (returnFilecni && returnFilecni.data) {
          this.init();
          console.log("user:",this.idUser)
          console.log("CniImage:", returnFilecni);
        }
      }
    },
    async addProfil(){
      this.showModalprofil = false;
      if(this.fileProfil){
        let formData = new FormData();
        formData.append("id", this.idUser);
        formData.append("avatar", this.fileProfil);
        
        const returnFileProfil = await apiRequest(
          "POST",
          "admin/user-pic",
          formData,
          true
        );

        if(returnFileProfil && returnFileProfil.data){
          console.log("Profil",returnFileProfil.data)
          this.init();
        }
      }
    },
    async addPermis(){
      this.showModalpermis = false;
      if(this.filePermis){
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.filePermis);

        const returnFilePermis = await apiRequest(
          "POST",
          "admin/user-permis-pic",
          formData,
          true
        );

        if(returnFilePermis && returnFilePermis.data){
          console.log("Permis",returnFilePermis.data)
          this.init();
        }
      }
    },
    async addAutoMoto() {
      this.showModalautoMoto = false;
      if(this.fileMoto){
        let formData = new FormData();
        formData.append("idUser",this.idUser);
        formData.append("file",this.fileMoto);

        const returnFileAutoMoto = await apiRequest(
          "POST",
          "admin/user-automoto-pic",
          formData,
          true
        );

        if(returnFileAutoMoto && returnFileAutoMoto.data){
          console.log("AutoMoto",returnFileAutoMoto.data)
          this.init();
        }
      }
    },
  },

  CloseModalProfil() {
    this.showModalprofil = false;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <a class="image-popup-vertical-fit ml-5" @click="showImage">
                  <img
                    style="cursor: pointer"
                    class=" avatar-xl"
                    :src="`${this.allImage.avatar}`"
                    data-holder-rendered="true"
                  />
                  <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->
                </a>
                <vue-easy-lightbox
                  :visible="vue"
                  :imgs="img"
                  @hide="handleHide"
                ></vue-easy-lightbox>
                <div class="ml-2">
                  <div class="ml-4 mt-2">
                    {{ user.username }}
                  </div>
                  <div v-if="user.levelRate < 50">
                    <star-rating
                      class="ml-3 mt-2"
                      :rating="1"
                      :read-only="true"
                      :star-size="28"
                      :border-width="2"
                      border-color="#d8d8d8"
                      :rounded-corners="true"
                      :max-rating="3"
                      :show-rating="false"
                    ></star-rating
                    ><span
                      style="font-weight:bold; color:black;"
                      class=" ml-2"
                      >{{ user.level }}</span
                    >
                  </div>
                  <div v-else-if="user.levelRate < 99">
                    <star-rating
                      class="ml-3 mt-2"
                      :rating="2"
                      :read-only="true"
                      :star-size="28"
                      :border-width="2"
                      border-color="#d8d8d8"
                      :rounded-corners="true"
                      :max-rating="3"
                      :show-rating="false"
                    ></star-rating
                    ><span
                      style="font-weight:bold; color:black;"
                      class="ml-2"
                      >{{ user.level }}</span
                    >
                  </div>
                  <div v-else>
                    <star-rating
                      class="ml-3 mt-2"
                      :rating="3"
                      :read-only="true"
                      :star-size="28"
                      :border-width="2"
                      border-color="#d8d8d8"
                      :rounded-corners="true"
                      :max-rating="3"
                      :show-rating="false"
                    ></star-rating
                    ><span
                      style="font-weight:bold; color:black;"
                      class=" ml-2"
                      >{{ user.level }}</span
                    >
                  </div>
                </div>
                <div class="ml-5">
                  <div class="mt-2">
                    <div>
                      <i class="text-warning mdi mdi-star"></i
                      >{{ user.rating }}/5 - 0 avis recus
                    </div>
                    <div class="ml-3 mt-2">
                      <i class="mdi mdi-car mr-1"></i>{{ user.usertype }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <i class="mdi mdi-phone mr-1"></i>{{ user.tel }}
                    <div class="mt-2">
                      <i class="mdi mdi-gmail mr-1"></i>{{ user.email }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!-- <div class="mt-4 mt-md-0" v-for="(item, index) in verify"
                  :key="index"> -->
                <div v-if="this.verify.email === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  E-mail vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  E-mail non vérifié
                </div>

                <div v-if="this.verify.permis === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Permis vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Permis non vérifié
                </div>
                <div v-if="this.verify.autoMoto === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Véhicule vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Véhicule non vérifié
                </div>
                <div v-if="this.verify.image === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Photo de profil vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Photo de profil non vérifié
                </div>
                <div v-if="this.verify.tel === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Numéro de téléphone vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Numéro de téléphone non vérifié
                </div>
                <div v-if="this.verify.cni === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Pièce d'identité vérifiée
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Pièce d'identité non vérifiée
                </div>
                <!-- </div> -->
              </div>

              <div class="col-md-3">
                <!-- <div class="mt-4 mt-md-0" v-for="(item, index) in preference"
                  :key="index"> -->
                <div
                  v-if="this.preference.acceptHandicape == 'NEVER'"
                  class="mt-4"
                >
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Je n'accpete pas les PMR
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2 "
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  J'accepte les PMR
                </div>

                <div v-if="this.preference.discutIncar == 'NEVER'" class="mt-4">
                  <img
                    class="mr-2 "
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Je n'aime pas discuter en voiture
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />J'aime discuter en voiture
                </div>
                <div v-if="this.preference.eatIncar == 'NEVER'" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Je n'aime pas manger en voiture
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  J'aime manger en voiture
                </div>
                <div v-if="this.preference.musicIncar == 'NEVER'" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Je n'écoute pas la musique en voiture
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  J'écoute la musique en voiture
                </div>
                <div v-if="this.preference.smoke == 'NEVER'" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Je n'aime pas fumer en voiture
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  J'aime fumer en voiture
                </div>
                <div v-if="this.preference.stop == 'NEVER'" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Je ne tolère pas les arrêts
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Je tolère les arrêts
                </div>
                <!-- </div> -->
              </div>

              <div class=" col-md-2 ml-5 float-right" style="margin-top:80px;">
                <div v-if="this.user.active == true">
                  <button
                    type="submit"
                    @click="desactiverUser"
                    class="btn btn-warning mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Désactiver ce
                    compte
                  </button>
                </div>
                <div v-else>
                  <button
                    type="submit"
                    @click="ActiverUser"
                    class="btn btn-info mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Activer ce compte
                  </button>
                </div>
                <button type="submit" class="btn btn-danger mb-2 mr-2">
                  <i class="ri-close-line align-middle mr-2"></i>Supprimer ce
                  compte
                </button>

                <div v-if="this.user.badge == true">
                  <button
                    type="submit"
                    @click="retirerBadge"
                    class="btn btn-info mb-2 mr-2"
                  >
                    <i class="mdi mdi-certificate align-middle mr-2"></i>Retirer
                    badge
                  </button>
                </div>
                <div v-else>
                  <button
                    type="submit"
                    @click="attribuerBadge"
                    class="btn btn-success mb-2 mr-2"
                  >
                    <i class="mdi mdi-certificate align-middle mr-2"></i
                    >Attribuer un badge
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body mt-3">
          <b-tabs pills justified content-class="p-3 text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-clipboard-account mr-2"></i>Vérifications &
                  Avis</span
                >
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <h3 class="mb-5">Vérifications</h3>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-3">
                              <div>
                                <h5 class="text-center">PROFIL</h5>
                                <a
                                  class="image-popup-vertical-fit"
                                  @click="showImage"
                                >
                                  <img
                                    class="avatar"
                                    alt
                                    :src="`${this.allImage.avatar}`"
                                    width="250"
                                    height="200"
                                  />
                                </a>
                                <vue-easy-lightbox
                                  :visible="visible"
                                  :imgs="imgs"
                                  @hide="handleHide"
                                ></vue-easy-lightbox>
                                <div class="row justify-content-center mt-4">
                                  <div>
                                    <button
                                      @click="showModalprofil = true"
                                      class="btn btn-primary"
                                    >
                                      <i class="fa fa-upload"></i>
                                    </button>
                                    <button
                                      @click="verifyPictureRefuse"
                                      id="echec"
                                      class="btn btn-danger"
                                    >
                                      <i class="fa fa-times"></i>
                                    </button>
                                    <button
                                      v-if="this.verify.image == false"
                                      @click="verifyPictureAccepte"
                                      id="validate"
                                      class="btn btn-success"
                                    >
                                      <i class="fa fa-check"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div>
                                <h5 class="text-center">CNI</h5>
                                <a
                                  class="image-popup-vertical-fit"
                                  @click="showImage"
                                >
                                  <img
                                    class="avatar"
                                    alt
                                    :src="`${this.allImage.cni}`"
                                    width="250"
                                    height="200"
                                  />
                                </a>
                                <vue-easy-lightbox
                                  :visible="visible"
                                  :imgs="imgs"
                                  @hide="handleHide"
                                ></vue-easy-lightbox>
                                <div class="row justify-content-center mt-4">
                                  <div>
                                    <button
                                      @click="showModalcni = true"
                                      class="btn btn-primary"
                                    >
                                      <i class="fa fa-upload"></i>
                                    </button>
                                    <button
                                      @click="verifyCniRefuse"
                                      id="echec"
                                      class="btn btn-danger"
                                    >
                                      <i class="fa fa-times"></i>
                                    </button>
                                    <button
                                      v-if="this.verify.cni == false"
                                      @click="verifyCniAccepte"
                                      id="validate"
                                      class="btn btn-success"
                                    >
                                      <i class="fa fa-check"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div>
                                <h5 class="text-center">PERMIS</h5>
                                <a
                                  class="image-popup-vertical-fit"
                                  @click="showImage"
                                >
                                  <img
                                    class="avatar"
                                    alt
                                    :src="`${this.allImage.permis}`"
                                    width="250"
                                    height="200"
                                  />
                                </a>
                                <vue-easy-lightbox
                                  :visible="visible"
                                  :imgs="imgs"
                                  @hide="handleHide"
                                ></vue-easy-lightbox>
                                <div class="row justify-content-center mt-4">
                                  <div>
                                    <button
                                      @click="showModalpermis = true"
                                      class="btn btn-primary"
                                    >
                                      <i class="fa fa-upload"></i>
                                    </button>
                                    <button
                                      @click="verifyPermisRefuse"
                                      id="echec"
                                      class="btn btn-danger"
                                    >
                                      <i class="fa fa-times"></i>
                                    </button>
                                    <button
                                      v-if="this.verify.permis == false"
                                      @click="verifyPermisAccepte"
                                      id="validate"
                                      class="btn btn-success"
                                    >
                                      <i class="fa fa-check"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div>
                                <h5 class="text-center">Auto/Moto</h5>
                                <a
                                  class="image-popup-vertical-fit"
                                  @click="showImage"
                                >
                                  <img
                                    class="avatar"
                                    alt
                                    :src="`${this.allImage.vehicule}`"
                                    width="250"
                                    height="200"
                                  />
                                </a>
                                <vue-easy-lightbox
                                  :visible="visible"
                                  :imgs="imgs"
                                  @hide="handleHide"
                                ></vue-easy-lightbox>
                                <div class="row justify-content-center mt-4">
                                  <div>
                                    <button
                                      @click="showModalautoMoto = true"
                                      class="btn btn-primary"
                                    >
                                      <i class="fa fa-upload"></i>
                                    </button>
                                    <button
                                      @click="verifyVehiculeRefuse"
                                      id="echec"
                                      class="btn btn-danger"
                                    >
                                      <i class="fa fa-times"></i>
                                    </button>
                                    <button
                                      v-if="this.verify.autoMoto == false"
                                      @click="verifyVehiculeAccepte"
                                      id="validate"
                                      class="btn btn-success"
                                    >
                                      <i class="fa fa-check"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-title">
                          <h3 class="mb-5">Information Auto/Moto</h3>
                        </div>
                        <b-card
                          header-class="bg-transparent border-primary"
                          class="border border-info"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <a
                                class="image-popup-vertical-fit"
                                @click="showImage"
                              >
                                <img
                                  class="avatar"
                                  alt
                                  :src="`${this.allImage.vehicule}`"
                                  width="250"
                                  height="200"
                                />
                              </a>
                              <vue-easy-lightbox
                                :visible="visible"
                                :imgs="imgs"
                                @hide="handleHide"
                              ></vue-easy-lightbox>
                            </div>
                            <div class="col-md-4 mt-5">
                              <div class="mt-2">
                                {{ AutoMoto.nomAuto + " " + AutoMoto.model }}
                              </div>
                              <div class="mt-2">
                                Couleur: {{ AutoMoto.couleur }}
                              </div>
                              <div class="mt-2">
                                Immatriculation: {{ AutoMoto.immatriculation }}
                              </div>
                              <div class="mt-2">
                                Motorisation: {{ AutoMoto.motorisation }}
                              </div>
                              <div class="mt-2">
                                Climatisation : {{ AutoMoto.climatisation }}
                              </div>
                            </div>
                            <div class="col-md-4 mt-5">
                              <div class="mt-2">
                                Catégorie : {{ AutoMoto.categorie }}
                              </div>
                              <div class="mt-2">
                                Année de circulation :
                                {{ AutoMoto.anneeCirculation }}
                              </div>
                              <div class="mt-2">
                                Numéro carte grise : {{ AutoMoto.cartegrise }}
                              </div>
                              <div class="mt-2">
                                Nombre de Siège : {{ AutoMoto.nombresiege }}
                              </div>
                            </div>
                          </div>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">
                        <h4>Avis</h4>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="card-title text-center mb-5">
                            <h5 class="bg-warning" style="font-size:20px;">
                              Avis reçus
                            </h5>
                          </div>
                          <div
                            class="media border-bottom pb-3"
                            v-for="(item, index) in avisrecus"
                            :key="index"
                          >
                            <div class="media-body">
                              <div>
                                <p class="text-muted mt-2">
                                  {{ item.avis }}
                                </p>
                                <div class="row d-flex justify-content-between">
                                  <h5 class="font-size-15 mb-3 ml-2">
                                    {{
                                      item.lastname[0] + "." + item.firstname
                                    }}
                                  </h5>
                                  <span
                                    class="float-sm-right mt-0 font-size-12"
                                  >
                                    {{ item.dateAvis }}
                                  </span>
                                  <div
                                    class="float-right"
                                    v-if="item.rating == 1"
                                  >
                                    <div class="row">
                                      <button
                                        @click="verifyAvisAccepte(item.id)"
                                        class="btn btn-success"
                                        v-if="item.visible == false"
                                      >
                                        <i class="fa fa-check"></i>
                                      </button>
                                      <button
                                        @click="verifyAvisRefuse(item.id)"
                                        class="btn btn-danger"
                                      >
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card-title text-left">
                            <h5
                              class="bg-success text-center mb-5"
                              style="font-size:20px;"
                            >
                              Avis laissés
                            </h5>
                          </div>
                          <div
                            class="media border-bottom pb-3"
                            v-for="(item, index) in avislaisses"
                            :key="index"
                          >
                            <div class="media-body">
                              <div>
                                <p class="text-muted mt-2 ml-3">
                                  {{ item.avis }}
                                </p>
                                <div class="row d-flex justify-content-between">
                                  <h5 class="font-size-15 mb-3 ml-4">
                                    {{ user.username }}
                                  </h5>
                                  <span
                                    class="float-sm-right mt-0 font-size-12"
                                  >
                                    {{ item.dateAvis }}
                                  </span>
                                  <div
                                    class="float-right"
                                    v-if="item.rating == 1"
                                  >
                                    <div class="row">
                                      <button
                                        @click="verifyAvisAccepte(item.id)"
                                        class="btn btn-success"
                                        v-if="item.visible == false"
                                      >
                                        <i class="fa fa-check"></i>
                                      </button>
                                      <button
                                        @click="verifyAvisRefuse(item.id)"
                                        class="btn btn-danger"
                                      >
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-format-list-bulleted mr-2"></i
                  >Activités</span
                >
              </template>
              <div>
                <h2 class="text-primary">
                  En tant que
                  <span
                    style="cursor:pointer"
                    class="text-success"
                    @click="HistoriqueConducteur = true"
                    >conducteur</span
                  >
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets publiés
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-primary">{{ activity.publies }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent"
                      class="border border-success"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets effectués
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-success">{{ activity.effectues }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-danger"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets annulés
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-info">{{ activity.annulees }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-success"
                      class="border border-warning"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Trajets à venir
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-warning">{{ activity.a_venir }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-success"
                      class="blueG"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Gains
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-blueG">{{ activity.gains }} XOF</h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <h2 class="text-primary">
                  En tant que
                  <span
                    style="cursor:pointer"
                    class="text-success"
                    @click="HistoriquePassager = true"
                    >passager</span
                  >
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Réservations éffectuées
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-primary">
                          {{ activity.reservations_effectuees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent"
                      class="border border-success"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Voyages effectués
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-success">
                          {{ activity.voyages_effectues }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card
                      header-class="bg-transparent border-danger"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0">
                          Réservations annulés
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-info">
                          {{ activity.reservations_annulees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalprofil"
      title="Téléverser ce profil ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileProfil"
          ref="fileProfil"
          v-on:change="handleFileProfil"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalprofil = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addProfil"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalcni"
      title="Téléverser cette CNI ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileCni"
          ref="fileCni"
          v-on:change="handleFileCni"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalcni = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addCNI"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalpermis"
      title="Téléverser ce permis ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="filePermis"
          ref="filePermis"
          v-on:change="handleFilePermis"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalpermis = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addPermis"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalautoMoto"
      title="Téléverser ce Auto/Moto ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileMoto"
          ref="fileMoto"
          v-on:change="handleFileMoto"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalautoMoto = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addAutoMoto"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriqueConducteur"
      title="Historique Conducteur"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="conducteurData"
              :fields="conducteur"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriquePassager"
      title="Historique Passager"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="passagerData"
              :fields="passager"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Badge attribué avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showretire"
      title="Badge retiré avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="Badge désactivé"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
</style>
